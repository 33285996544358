import React, { useState, createContext } from 'react';
import qs from 'query-string';

import Api from './api';
import { setTimeout } from 'core-js';
import Cookies from 'js-cookie';

const defaultState = {
  theme: 5,
  token: null,
  slug: null,
  preEvent: false,
  loaded: false,
  responseFailed: false,
  cms: null,
  initialSlide: 0,
  pagination: 0,
  paginationTitle: 'Welcome',
  swiperInstance: null,
  nextButton: 'Next',
  prevButton: 'Prev',
  showNext: true,
  showPrev: false,
  connectButton: false,
  submitButton: false,
  buttonDisabled: false,
  buttonSmall: false,
  submitOutside: false,
  endScreen: false,
  showGlobals: false,
  eventName: '',
  vip: false,
};

export const StoreContext = createContext(defaultState);

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ({ children }) => {
  const [theme, setTheme] = useState(defaultState.theme);
  const [pagination, setPagination] = useState(defaultState.pagination);
  const [swiperInstance, setSwiperInstance] = useState(defaultState.swiperInstance);
  const [initialSlide, setInitialSlide] = useState(defaultState.initialSlide);
  const [paginationTitle, setPaginationTitle] = useState(defaultState.paginationTitle);
  const [slug, setSlug] = useState(defaultState.slug);
  const [loaded, setLoaded] = useState(defaultState.loaded);
  const [responseFailed, setResponseFailed] = useState(defaultState.responseFailed);
  const [cms, setCms] = useState(defaultState.cms);
  const [preEvent, setPreEvent] = useState(defaultState.preEvent);
  const [nextButton, setNextButton] = useState(defaultState.nextButton);
  const [showNext, setShowNext] = useState(defaultState.showNext);
  const [prevButton, setPrevButton] = useState(defaultState.prevButton);
  const [showPrev, setShowPrev] = useState(defaultState.showPrev);
  const [connectButton, setConnectButton] = useState(defaultState.connectButton);
  const [submitButton, setSubmitButton] = useState(defaultState.submitButton);
  const [buttonDisabled, setButtonDisabled] = useState(defaultState.buttonDisabled);
  const [buttonSmall, setButtonSmall] = useState(defaultState.buttonSmall);
  const [submitOutside, setSubmitOutside] = useState(defaultState.submitOutside);
  const [vip, setVip] = useState(defaultState.setVip);
  const [endScreen, setEndScreen] = useState(defaultState.endScreen);
  const [showGlobals, setShowGlobals] = useState(defaultState.showGlobals);
  const [eventName, setEventName] = useState(defaultState.eventName);

  const getCMS = param => {
    const cmsSubmit = new Api({ url: 'api/invite/cms' });

    cmsSubmit
      .submit({ slug: param })
      .then(response => {
        if (Object.keys(response).length === 0) {
          setResponseFailed(true);
        } else {
          setEventName(response.content.event.name);
          addFlags(response);
          slugCookie(param);
          setCms(response);
          setTimeout(function() {
            setLoaded(true);
          }, 6000);
        }
      })
      .catch(error => {
        console.log(error);
        setLoaded(false);
      });
  };

  const getResults = (param, location) => {
    const query = qs.parse(location.search);
    const cmsSubmit = new Api({
      url: 'api/invite/user/analyser',
      key: query.token,
    });

    const params = {
      type: 'all',
      slug: param.slug,
      group_id: localStorage.getItem('group_id'),
    };

    window.history.pushState(null, '', window.location.href.split('?')[0]);

    cmsSubmit
      .submit(params)
      .then(response => {
        if (Object.keys(response).length === 0) {
          setResponseFailed(true);
        } else {
          setEventName(response.content.event.name);
          addFlags(response);
          setCms(response);
          if (response.content.event.vip) {
            setInitialSlide(0);
          } else {
            setInitialSlide(1);
          }
          setTimeout(function() {
            setLoaded(true);
          }, 3000);
        }
      })
      .catch(error => {
        console.log(error);
        setLoaded(false);
      });
  };

  const spotifyConnect = () => {
    const connect = new Api({ url: '/api/invite/connect' });

    const params = {
      slug,
      first_name: localStorage.getItem('first_name'),
      last_name: localStorage.getItem('last_name'),
      email: localStorage.getItem('email'),
      group_id: localStorage.getItem('group_id'),
      env: 'env',
    };
    if (process.env.NODE_ENV === 'development') {
      params.env = 'dev';
    }

    setLoaded(false);

    return connect
      .submit(params)
      .then(response => {
        return response.url;
      })
      .then(url => {
        window.location.href = url;
      })
      .catch(error => {
        console.log(error);
        // setLoaded(true);
      });
  };

  const addFlags = response => {
    response.content.pages.forEach((res, i) => {
      if (res.template === 'ImageRightLockup') {
        if (res.slug === 'welcome' || res.slug === 'inspiration') {
          res.content.gradient = false;
        } else {
          res.content.gradient = true;
        }
      }
    });
  };

  const slugCookie = param => {
    const expirey = 1 / 24;

    Cookies.set('slug', param, { expires: expirey });
  };

  const store = {
    theme,
    setTheme,
    pagination,
    setPagination,
    swiperInstance,
    setSwiperInstance,
    initialSlide,
    setInitialSlide,
    paginationTitle,
    setPaginationTitle,
    loaded,
    setLoaded,
    responseFailed,
    setResponseFailed,
    preEvent,
    setPreEvent,
    nextButton,
    setNextButton,
    showNext,
    setShowNext,
    prevButton,
    setPrevButton,
    showPrev,
    setShowPrev,
    connectButton,
    setConnectButton,
    submitButton,
    setSubmitButton,
    buttonDisabled,
    setButtonDisabled,
    buttonSmall,
    setButtonSmall,
    submitOutside,
    setSubmitOutside,
    endScreen,
    setEndScreen,
    cms,
    setCms,
    getCMS,
    getResults,
    slug,
    setSlug,
    spotifyConnect,
    showGlobals,
    setShowGlobals,
    eventName,
    setEventName,
    vip,
    setVip,
  };

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

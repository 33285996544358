import React, { useContext } from 'react';
import dompurify from 'dompurify';
import { StoreContext } from '../../../utils/Store';
import gradient from '../../../../assets/img/gradient-horizontal.png';
import { ReactComponent as SpotifyLogo } from '../../../../assets/img/logo-spotify-advertising.svg';
import styles from './fullWidthLockup.module.scss';

const FullWidthLockup = page => {
  let prop = null;
  const sanitizer = dompurify.sanitize;
  const { swiperInstance, setEndScreen, endScreen } = useContext(StoreContext);

  if (page.content !== undefined) {
    prop = page.content.content;
  } else {
    prop = page.page;
  }

  const ReturnToAudience = () => {
    swiperInstance.slideTo(0);
    setEndScreen(false);
  };

  return (
    <div
      className={`${styles.fullWidth} ${
        endScreen ? 'pt-40 pb-12' : 'py-40'
      } min-h-screen px-8 md:px-24 md:h-screen md:flex md:items-center md:justify-center relative md:py-48 lg:py-64 xl:pb-24 xl:pt-48`}
    >
      {prop.theme && (
        <div
          data-theme={prop.theme}
          className={`${styles.fullWidth__theme} bg--secondary h-full left-0 absolute top-0 w-full`}
        />
      )}
      <div data-theme={prop.theme}>
        {/* eslint-disable react/no-danger */}
        <h1 className="h2 mb-12" dangerouslySetInnerHTML={{ __html: sanitizer(prop.heading) }} />
        <p className={`${styles.fullWidth__description} mb-16 md:mb-32`}>{prop.description}</p>
        <a
          href={prop.cta.link}
          className="button mb-24 md:mb-56 xl:mb-40"
          data-theme={prop.theme}
          target="_blank"
          rel="noreferrer"
        >
          <span>{prop.cta.label}</span>
        </a>
        {prop.restart.heading && (
          <div className={`${styles.fullWidth__restartLockup} p-8 md:p-16 lg:p-24`}>
            <p className={`${styles.fullWidth__restartHeading} color--secondary font-bold pb-20`}>
              {prop.restart.heading}
            </p>
            <button
              type="button"
              className="button button--transparent-reverse"
              data-theme={prop.theme}
              onClick={() => ReturnToAudience()}
            >
              <span>{prop.restart.cta.label}</span>
            </button>
            <div className={`${styles.fullWidth__restartLockupGradient} absolute flex items-center justify-between`}>
              <img className={styles.image__gradient} src={gradient} alt="" />
              <img className={styles.image__gradient} src={gradient} alt="" />
            </div>
          </div>
        )}
        {endScreen && (
          <div className={`${styles.fullWidth__logo} mt-24 xl:mt-40`}>
            <span className="sr-only">Spotify Advertising</span>
            <SpotifyLogo />
          </div>
        )}
      </div>
    </div>
  );
};

export default FullWidthLockup;

import React from 'react';
import BubbleChart from './BubbleChart';
import styles from './bubbles.module.scss';

const Bubbles = page => {
  const prop = page.content.content;
  const genres = prop.data;

  return (
    <div
      className={`${styles.bubbles} md:h-screen md:flex md:flex-col md:justify-center md:items-center pt-40 pb-20 md:pt-32 md:pb-48 lg:py-56 xl:py-48 mx-auto`}
    >
      <div className="w-full">
        <div className={`${styles.bubbles__heading} heading xl:flex xl:justify-between w-full mx-auto md:mt-20`}>
          <div>
            <p className={`${styles.bubbles__subtitle} mb-4 xl:mb-0`}>{prop.group_name}</p>
            <div className="text-block-animation">
              <h1 className={`${styles.bubbles__title} mb-4 xl:mb-0`}>{prop.heading}</h1>
            </div>
          </div>
          <p className={`${styles.bubbles__description} mb-10 xl:mb-0`}>{prop.description}</p>
        </div>
        <div className={`${styles.bubbles__mobileList} md:hidden px-10 pt-10 pb-4`}>
          {genres.map((genre, index) => {
            return (
              <p key={genre.name} className={`${styles.bubbles__mobileItem} capitalize pb-6`}>
                <span className="mr-4">{parseFloat(Math.round(genre.percentage))}%</span> {genre.name}
              </p>
            );
          })}
        </div>
        <div className="hidden md:block">
          <BubbleChart data={genres} background="lightgreen" fontColor="white" width={970} height={460} />
        </div>
      </div>
    </div>
  );
};

export default Bubbles;

import React from 'react';

const FourOFour = () => (
  <div className="pt-10 h-screen flex items-center justify-items-center flex-col py-20 lg:py-40">
    <h1>How did you get here?</h1>
    <p>It looks like you’re in the wrong place unfortunately!</p>
  </div>
);

export default FourOFour;

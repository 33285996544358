import React from 'react';
import { Switch, BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';
import Home from './routes/Home';
import FourOFour from './routes/FourOFour';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/404" exact>
          <FourOFour />
        </Route>
        <Route
          path="/"
          exact
          render={() => {
            if (Cookies.get('slug') !== 'undefined') {
              window.location.replace(window.location.href + Cookies.get('slug'));
            } else {
              <FourOFour />;
            }
          }}
        />
        <Route path="/:slug" render={({ location, match }) => <Home location={location} params={match.params} />} />
        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default App;

/* eslint-disable operator-linebreak */
/**
 * @param   {Element}       el
 * @param   {string}        selector
 * @param   {boolean}       [includeSelf]
 * @return  {Element|null}
 */

export default function closestParent(el, selector, includeSelf) {
  let parent = el.parentNode;

  // Polyfill for element.matches
  // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
  if (Element && !Element.prototype.matches) {
    const proto = Element.prototype;
    proto.matches =
      proto.matchesSelector ||
      proto.mozMatchesSelector ||
      proto.msMatchesSelector ||
      proto.oMatchesSelector ||
      proto.webkitMatchesSelector;
  }

  if (includeSelf && el.matches(selector)) {
    return el;
  }

  while (parent && parent !== document.body) {
    if (parent.matches && parent.matches(selector)) {
      return parent;
    }
    if (parent.parentNode) {
      parent = parent.parentNode;
    } else {
      return null;
    }
  }

  return null;
}

import React, { useContext } from 'react';
import SVG from 'react-inlinesvg';
import { Link } from 'react-scroll';

import styles from './imageRightLockup.module.scss';
import gradient from '../../../../assets/img/gradient.png';

import { StoreContext } from '../../../utils/Store';

import Arrow from '../../../../assets/img/arrow.svg';
import Image from '../../../../assets/img/gradient.png';

import Carousel from './Carousel';
import Calendar from '../../AddToCalendar/Calendar';

const ImageRightLockup = page => {
  let prop = null;
  const { endScreen } = useContext(StoreContext);

  if (page.content !== undefined) {
    prop = page.content.content;
  } else {
    prop = page.page;
  }

  return (
    <div
      className={`${styles.image} ${
        endScreen ? 'md:px-24' : 'md:px-0 md:h-screen'
      } pt-40 pb-20 px-8 md:flex md:items-center md:justify-center md:pb-20 md:pt-48 lg:py-64 xl:py-48 relative`}
    >
      {prop.theme !== null && (
        <div data-theme={prop.theme} className="bg--secondary h-full left-0 absolute top-0 w-full mx-auto" />
      )}
      <div
        className={`${styles.image__wrapper} ${
          prop.template === 'ImageLeftLockup' ? 'xl:flex-row-reverse' : ''
        } lg:flex lg:items-center lg:flex-col lg:justify-between lg:w-full xl:flex-row`}
      >
        <div className={`${styles.image__headings} heading-image-right lg:mr-8`} data-theme={prop.theme}>
          {prop.group_name && <p className={`${styles.image__subtitle} mb-4`}>{prop.group_name}</p>}
          {prop.heading && (
            <div className="text-block-animation">
              <h2 className={`${styles.image__title} ${prop.template === 'ImageLeftLockup' ? 'xl:mb-32' : ''} mb-10`}>
                {prop.heading}
              </h2>
            </div>
          )}
          {prop.body && (
            <p
              className={`${styles.image__description} ${
                prop.cta || prop.learn_more ? 'mb-12' : 'mb-20 lg:mb-48 xl:mb-0'
              }`}
            >
              {prop.body}
            </p>
          )}
          {prop.event_name && <h3 className={`mb-2 relative`}>{prop.event_name}</h3>}
          {prop.date_time_formatted && (
            <p
              className={`${styles.image__description} ${
                prop.cta || prop.learn_more ? 'mb-12' : 'mb-20 lg:mb-48 xl:mb-0'
              }`}
            >
              {prop.date_time_formatted}
            </p>
          )}
          {prop.description && (
            <p
              className={`${styles.image__description} ${
                prop.cta || prop.learn_more ? 'mb-12' : 'mb-20 lg:mb-48 xl:mb-0'
              } ${prop.data ? `${styles.image__descriptionMoments}` : ''}`}
            >
              {prop.description}
            </p>
          )}
          {prop.source_description && (
            <p className={`${styles.image__descriptionSmall} mt-16 mb-12`}>{prop.source_description}</p>
          )}
          {prop.learn_more && (
            <a
              className={`${styles.image__button} button--transparent button--lowercase button mb-16 xl:mb-0`}
              href={prop.learn_more.link}
              rel="noreferrer"
              target="_blank"
            >
              <span>{prop.learn_more.label}</span>
            </a>
          )}
          {prop.cta && !prop.date_time && (
            <a
              className={`${styles.image__button} ${
                !endScreen ? 'button--transparent button--lowercase' : ''
              } button mb-24 xl:mb-0`}
              href={prop.cta.url}
              rel="noreferrer"
              target="_blank"
            >
              <span>{prop.cta.label}</span>
            </a>
          )}
          {prop.date_time && <Calendar buttonText={prop.cta.label} prop={prop} className="mb-24 xl:mb-0" />}
        </div>

        {endScreen && prop.images && <Carousel data={prop} />}

        {!prop.data && !prop.images && (
          <div className={styles.image__imageContainer}>
            {prop.gradient && (
              <div className={styles.image__gradientWrapper}>
                <img className={styles.image__gradient} src={gradient} alt="" />
              </div>
            )}
            <img
              src={prop.image}
              alt={prop.group_name}
              className={`${styles.image__image} ${
                prop.template === 'ImageLeftLockup' ? 'xl:ml-0' : 'xl:mr-0'
              } image-right-lockup-image mx-auto object-contain`}
            />
          </div>
        )}

        {prop.data && (
          <div>
            <div className={`${styles.image__mobileList} md:hidden px-10 pt-10 pb-4`}>
              {prop.data.map((item, index) => {
                return (
                  <p className={`${styles.image__mobileItem} pb-6`} key={item}>
                    <span className="mr-4">{index + 1}.</span>
                    {item}
                  </p>
                );
              })}
            </div>
            <div className={`${styles.image__phoneWrapper} hidden md:flex`}>
              <div className={styles.image__gradientClip}>
                <img className={styles.image__gradient} src={Image} alt="gradient background" />
              </div>
              <div className={styles.image__whiteBg}>
                <div className={styles.image__colorBg}>
                  <div className={`${styles.image__screen}`}>
                    {prop.data.map((item, index) => {
                      return (
                        <p key={item}>
                          <span className="pr-4">{index + 1}.</span>
                          {item}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {endScreen && prop.template === 'ImageRightLockup' && (
        <Link
          className={`${styles.image__anchor} color--secondary`}
          data-theme={prop.theme}
          activeClass="active"
          smooth="true"
          duration={500}
          to={'child--2'}
        >
          <SVG src={Arrow} />
        </Link>
      )}
    </div>
  );
};

export default ImageRightLockup;

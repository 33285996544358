import React from 'react';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';

import Button from './CalendarButton';
import Modal from './CalendarModal';

const AddToCalendarModal = AddToCalendarHOC(Button, Modal);

export default function Calendar({ buttonText, className, prop }) {
  const checkTime = i => {
    return i < 10 ? ['0', i].join('') : i;
  };
  const startDate = new Date(prop.date_time);
  const endDate = new Date(prop.end_date_time);
  const startMonth = startDate.getMonth() + 1;
  const startDay = startDate.getDate();
  const startYear = startDate.getFullYear();
  const startHours = startDate.getHours();
  const startMinutes = checkTime(startDate.getMinutes());
  const startSeconds = checkTime(startDate.getSeconds());
  const endMonth = endDate.getMonth() + 1;
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();
  const endHours = endDate.getHours();
  const endMinutes = checkTime(endDate.getMinutes());
  const endSeconds = checkTime(endDate.getSeconds());
  const formattedStartDate = [startYear, startMonth, startDay, 'T', startHours, startSeconds, startMinutes, 'Z'].join('');
  const formattedEndDate = [endYear, endMonth, endDay, 'T', endHours, endSeconds, endMinutes, 'Z'].join('');

  const event = {
    duration: 0,
    endDatetime: formattedEndDate,
    location: '',
    startDatetime: formattedStartDate,
    title: prop.event_name,
  };
  return <AddToCalendarModal event={event} buttonText={buttonText} className={className} />;
}

import React from 'react';
import styles from './endScreen.module.scss';

// components
import ImageRightLockup from '../ImageRightLockup/ImageRightLockup';
import FullWidthLockup from '../FullWidthLockup/FullWidthLockup';

const EndScreen = page => {
  const slideProps = page.content.slides;

  return (
    <div className={`${styles.endScreen} flex flex-col items-center`}>
      {slideProps.map((slide, index) => {
        return (
          <div name={`child--${index + 1}`} key={slide.template} className={`${styles.endScreen__child} w-full`}>
            {slide.template === 'ImageRightLockup' && <ImageRightLockup page={slide} />}
            {slide.template === 'ImageLeftLockup' && <ImageRightLockup page={slide} />}
            {slide.template === 'FullWidthLockup' && <FullWidthLockup page={slide} />}
          </div>
        );
      })}
    </div>
  );
};

export default EndScreen;

/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as d3 from 'd3';
import styles from './bubbles.module.scss';

const floatNum = num => {
  return parseFloat(Math.round(num));
};

export default class BubbleChart extends React.Component {
  constructor(props) {
    super(props);

    this.minValue = 1;
    this.maxValue = 100;
    this.mounted = false;
    this.state = { data: [] };

    this.radiusScale = this.radiusScale.bind(this);
    this.simulatePositions = this.simulatePositions.bind(this);
    this.renderBubbles = this.renderBubbles.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    this.mounted = true;

    if (data.length > 0) {
      this.minValue = 0.95 * d3.min(data, item => floatNum(item.percentage));
      this.maxValue = 1.05 * d3.max(data, item => floatNum(item.percentage));

      this.simulatePositions(data);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  simulatePositions(dat) {
    d3.forceSimulation()
      .nodes(dat)
      .velocityDecay(0.2)
      .force('x', d3.forceX().strength(0.05))
      .force('y', d3.forceY().strength(0.45))
      .force(
        'collide',
        d3.forceCollide(d => this.radiusScale(floatNum(d.percentage)) + 10)
      )
      .on('tick', () => {
        if (this.mounted) {
          this.setState({ data: dat });
        }
      });
  }

  radiusScale(value) {
    const fx = d3
      .scaleSqrt()
      .domain([this.minValue, this.maxValue])
      .range([50, 150]);
    return fx(value);
  }

  renderBubbles(data) {
    const { width, height } = this.props;

    const texts = _.map(data, (item, index) => {
      const fontSize = this.radiusScale(floatNum(item.percentage));
      return (
        <g
          key={index}
          transform={`translate(${width / 2 + item.x}, ${height / 2 + item.y})`}
          className={styles.bubbles__bubble}
        >
          <circle className="fill--primary" r={this.radiusScale(floatNum(item.percentage))} />
          {item.show_percentage && (
            <text className="fill--secondary font-bold f-sans" textAnchor="middle" fontSize={`${fontSize / 3.5}px`}>
              {`${floatNum(item.percentage)}%`}
            </text>
          )}
          <text
            className="fill--secondary font-bold f-sans capitalize"
            dy={item.show_percentage ? fontSize / 3.5 : fontSize / 12}
            textAnchor="middle"
            fontSize={`${item.name.length >= 20 ? fontSize / 8 : fontSize / 5.5}px`}
          >
            {item.name}
          </text>
        </g>
      );
    });

    return texts;
  }

  render() {
    const { width, height } = this.props;
    if (this.state.data.length) {
      return (
        <svg className={styles.bubbles__svg} viewBox={`0, 0, ${width}, ${height}`}>
          {this.renderBubbles(this.state.data)}
        </svg>
      );
    }

    return <div>Loading</div>;
  }
}

BubbleChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

BubbleChart.defaultProps = {
  width: 1000,
  height: 600,
};

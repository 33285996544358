import React from 'react';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styles from './imageRightLockup.module.scss';

SwiperCore.use([Autoplay]);

const Carousel = data => {
  return (
     <Swiper
      className={`${styles.image__carousel} image-carousel`}
      allowTouchMove={false}
      slidesPerView={1}
      centeredSlides={false}
      virtualTranslate
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
        waitForTransition: false,
      }}
    >
      {data.data.images.map((image, index) => {
        return (
          <SwiperSlide className={`${styles.image__carouselSlide} ${`swiper-slide--${index + 1}`}`} key={`swiper-slide--${index + 1}`}>
            <div className={styles.image__carouselSlideWrapper}>
              <img
                src={image}
                alt={data.data.group_name}
                className={styles.image__carouselImage}
              />
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  )
};

export default Carousel;

import React, { useEffect, useContext } from 'react';
import { StoreContext } from '../utils/Store';
import { CSSTransition } from 'react-transition-group';

// components
import Loader from '../components/Loader/Loader';
import FourOFour from './FourOFour';
import MainBoard from '../layouts/MainBoard';

const Home = ({ params, location }) => {
  const { getCMS, getResults, loaded, setSlug, responseFailed } = useContext(StoreContext);
  const loadingDelay = 2000;

  useEffect(() => {
    if (params) {
      setSlug(params.slug);
      if (location.search) {
        getResults(params, location);
      } else {
        getCMS(params.slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!params || responseFailed) {
    return <FourOFour />;
  }

  return (
    <CSSTransition appear in={loaded} timeout={loadingDelay}>
      {!loaded ? <Loader duration={loadingDelay} /> : <MainBoard />}
    </CSSTransition>
  );
};
export default Home;

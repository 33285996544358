import React, { useContext } from 'react';
import { StoreContext } from '../../utils/Store';
import styles from './footer.module.scss';

const Footer = () => {
  const {
    cms,
    pagination,
    swiperInstance,
    nextButton,
    showNext,
    prevButton,
    showPrev,
    connectButton,
    submitButton,
    buttonDisabled,
    buttonSmall,
    setSubmitOutside,
    endScreen,
    spotifyConnect,
    showGlobals,
  } = useContext(StoreContext);
  const { titles } = cms.content.pagination;

  const nextButtonAction = e => {
    if (connectButton) {
      spotifyConnect();
    } else if (submitButton) {
      setSubmitOutside(true);
    } else {
      swiperInstance.slideNext();
    }
    setTimeout(() => {
      e.target.blur();
    }, 500);
  };

  const backButtonAction = e => {
    swiperInstance.slidePrev();
    setTimeout(() => {
      e.target.blur();
    }, 500);
  };

  return (
    <footer
      className={`${!endScreen ? `${styles.footer}` : ''} ${
        !showGlobals ? `${styles.footer__hide}` : ''
      } relative flex flex-col md:flex-row-reverse md:items-center justify-between px-8 md:px-20 lg:px-32 w-full`}
    >
      <div
        className={`${styles.footer__navigation} ${!endScreen ? `mb-12` : ''} ${
          showPrev ? 'flex-row' : 'flex-col'
        } flex md:flex-row justify-between items-center md:mb-0`}
      >
        <button
          type="button"
          className={`${showPrev ? '' : 'button--hide'} button button--transparent`}
          onClick={e => backButtonAction(e)}
        >
          <span>{prevButton}</span>
        </button>
        <button
          type="button"
          className={`${showNext ? '' : 'button--hide'} ${buttonDisabled ? 'button--disabled' : ''} ${
            buttonSmall ? 'button--small' : ''
          } button md:ml-8`}
          onClick={e => nextButtonAction(e)}
        >
          <span>{nextButton}</span>
        </button>
      </div>
      {!endScreen && (
        <div className={`${styles.pagination} flex justify-center items-end mb-8 md:mb-0`}>
          {titles.map((title, i) => {
            return (
              <div className={`${pagination === i ? styles.active : ''} ${styles.pagination__item}`} key={title}>
                <p className={`${styles.pagination__title} mx-auto`}>{title}</p>
                <span className={styles.pagination__dot}> </span>
              </div>
            );
          })}
        </div>
      )}
    </footer>
  );
};

export default Footer;

import React from 'react';
import styles from './quadTiles.module.scss';
import Gradient from '../../../../assets/img/gradient-square.png';

const QuadTiles = page => {
  const prop = page.content.content;
  const podcasts = prop.data;

  return (
    <div
      className={`${styles.quadtiles} md:h-screen md:flex md:flex-col md:justify-center items-center pt-40 pb-20 md:pt-32 md:pb-48 lg:py-56 xl:py-48 mx-auto`}
    >
      <div className="w-full">
        <div className="heading xl:flex xl:justify-between xl:items-end w-full mb-10 xl:mb-24 mx-auto">
          <div className="mr-12">
            <p className={`${styles.quadtiles__subtitle} mb-4 xl:mb-0`}>{prop.group_name}</p>
            <div className="text-block-animation">
              <h1 className={`${styles.quadtiles__title} mb-4 xl:mb-0`}>{prop.heading}</h1>
            </div>
          </div>
          <p className={`${styles.quadtiles__description} mb-10 md:mb-20 xl:mb-0`}>{prop.description}</p>
        </div>
        <div className={`${styles.quadtiles__mobileList} md:hidden px-10 pt-10 pb-4`}>
          {podcasts.map((podcast, index) => {
            return (
              <div key={podcast.name}>
                {podcast.name && <p className={`${styles.quadtiles__mobileItem}`}>{podcast.name}</p>}
                {podcast.publisher && (
                  <p className={`${styles.quadtiles__mobileItem} ${styles.quadtiles__hosts} pb-6`}>
                    {podcast.publisher.toLowerCase()}
                  </p>
                )}
              </div>
            );
          })}
        </div>

        <div className="hidden md:grid gap-10 grid-cols-1 grid-rows-5 md:grid-cols-2 md:grid-rows-3 xl:grid-cols-3 xl:grid-rows-2">
          {podcasts.map((podcast, index) => {
            return (
              <div key={podcast.name} className={`${styles.quadtiles__podcast} flex pb-20 md:pb-12 lg:pb-32`}>
                <div className={styles.quadtiles__imageWrapper}>
                  <img src={Gradient} alt="gradient background" className={`${styles.quadtiles__gradient}`} />
                  {podcast.image && (
                    <img
                      src={podcast.image}
                      alt={podcast.name}
                      className={`${styles.quadtiles__image} quadtile-image opacity-0`}
                    />
                  )}
                </div>

                <div className={`${styles.quadtiles__text} quadtile-text pl-8 opacity-0`}>
                  {podcast.name && <p className={styles.quadtiles__name}>{podcast.name}</p>}
                  {podcast.publisher && <p className={`${styles.quadtiles__hosts}`}>{podcast.publisher}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QuadTiles;

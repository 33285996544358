import React from 'react';
import Modal from 'react-modal';
import Button from './CalendarButton';

export default function CalendarModal({ children, isOpen, onRequestClose }) {
  return (
    <Modal
      className="modal modal-calendar"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <h2>Add to Calendar</h2>
      <div>{children}</div>
      <Button onClick={onRequestClose}>Cancel</Button>
    </Modal>
  );
}

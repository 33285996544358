import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import CookieBar from './components/CookieBar/CookieBar';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import App from './App';
import { StoreContext } from './utils/Store';

const Container = () => {
  const { theme, loaded, eventName, endScreen } = useContext(StoreContext);
  const pageTitle = `${eventName} - Spotify Resonate`;

  return (
    <>
      <Helmet>
        {eventName && <title>{pageTitle}</title>}
        {!eventName && <title>Spotify Resonate</title>}
      </Helmet>
      <div className={`${endScreen ? '' : 'overflow-y-hidden'} bg bg--secondary min-h-screen`} data-theme={theme}>
        {loaded && <Header />}
        <App />
        {loaded && <Footer />}
        {loaded && <CookieBar />}
      </div>
    </>
  );
};

export default Container;

import React, { useContext, useState } from 'react';
import SwiperCore, { Virtual, EffectFade, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CSSTransition } from 'react-transition-group';

import styles, { animationDuration } from './main-board.module.scss';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/a11y/a11y.scss';

import { StoreContext } from '../utils/Store';

// components
import Components from '../utils/Components';

SwiperCore.use([Virtual, EffectFade, A11y]);

export default function MainBoard() {
  const {
    cms,
    pagination,
    initialSlide,
    swiperInstance,
    setTheme,
    setPagination,
    setSwiperInstance,
    setButtonDisabled,
    setButtonSmall,
    setShowNext,
    setShowPrev,
    setShowGlobals,
    setNextButton,
    setPrevButton,
    setConnectButton,
    setSubmitButton,
    setVip,
    setEndScreen,
    endScreen,
  } = useContext(StoreContext);
  const { pages } = cms.content;
  const vip = cms.content.event.vip;

  const transitionSpeed = parseInt(animationDuration, 10);
  const [showCarousel, setShowCarousel] = useState(false);

  const navigationBehaviour = page => {
    // update navigation button functions for forms and spotify connect
    switch (page.template) {
      case 'MultiSelect':
        setShowNext(true);
        setNextButton(page.content.connect.label);
        setConnectButton(true);
        setButtonDisabled(true);
        setButtonSmall(true);
        break;

      case 'FormRightLockup':
        setShowNext(true);
        setNextButton(page.content.next.label);
        if (vip) {
          localStorage.setItem('group_id', null);
          setVip(true);
        }
        setSubmitButton(true);
        setButtonDisabled(true);
        break;
      case 'EndScreen':
        setShowNext(false);
        setShowPrev(false);
        setSubmitButton(false);
        setConnectButton(false);
        setEndScreen(true);
        break;
      default:
        setButtonDisabled(false);
        setButtonSmall(false);
        setSubmitButton(false);
        setConnectButton(false);
        setEndScreen(false);
        setShowNext(true);
        setNextButton(page.content.next.label);

        if (page.content.back !== undefined) {
          setShowPrev(true);
          setPrevButton(page.content.back.label);
        } else {
          setShowPrev(false);
        }
    }
  };

  const updateGlobal = currentSlide => {
    pages.map((page, i) => {
      if (currentSlide === i) {
        setTheme(page.theme);
        navigationBehaviour(page);

        // update active pagination
        if (pagination !== page.pagination_id) setPagination(page.pagination_id);
      }
      return false;
    });
  };

  return (
    <main className={`${styles.carousel} ${!showCarousel ? styles.carousel__hide : ''}`}>
      <Swiper
        className={`${!endScreen ? 'main-board-carousel' : ''}`}
        virtual
        // effect="fade"
        allowTouchMove={false}
        initialSlide={initialSlide}
        speed={transitionSpeed}
        slidesPerView={1}
        onSlideChange={swiper => {
          const currentSlide = swiper.activeIndex;

          updateGlobal(currentSlide);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        onSwiper={swiper => {
          const currentSlide = swiper.activeIndex;

          updateGlobal(currentSlide);
          setSwiperInstance(swiper);
          setShowGlobals(true);
          setTimeout(() => {
            setShowCarousel(true);
          }, 1200);
        }}
      >
        {pages.map((page, index) => {
          return (
            <SwiperSlide
              className={`${styles.carousel__slide} ${`swiper-slide--${index + 1}`} ${
                !endScreen ? 'px-8 md:px-24' : styles.carousel__endScreen
              }`}
              key={page.id}
              virtualIndex={index}
            >
              <CSSTransition
                in={swiperInstance && swiperInstance.activeIndex === index}
                appear
                timeout={transitionSpeed}
                classNames={{ ...styles }}
              >
                <>{Components(page)}</>
              </CSSTransition>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </main>
  );
}

import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import animationData from './loader-animation.json';
import styles from './loader.module.scss';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: false,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const [stopped, setStopped] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setStopped(false);
    }, 1500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.loader}>
      <div className={styles.loaderAnimation}>
        <Lottie options={defaultOptions} isStopped={stopped} />
      </div>
    </div>
  );
};

export default Loader;

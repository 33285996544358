import React, { useContext, useState, useEffect} from 'react';
import Modal from 'react-modal';
import { StoreContext } from '../../utils/Store';
import { ReactComponent as SpotifyLogo } from '../../../assets/img/logo-spotify-advertising.svg';
import { ReactComponent as HelpIcon } from '../../../assets/img/help-icon.svg';
import styles from './header.module.scss';

const Header = theme => {
  const { cms, showGlobals } = useContext(StoreContext);
  const navLinks = cms.content.footer;
  const [navOpen, setNavOpen] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  
  const openModal = () => {
    setIsOpen(true);
  }
 
  const closeModal = () => {
    setIsOpen(false);
  }

  useEffect(() => {
    window.addEventListener('resize', closeModal)
  }, []);

  return (
    <header
      className={`${styles.header} ${
        !showGlobals ? `${styles.header__hide}` : ''
      } absolute left-0 top-0 flex justify-between content-center px-8 lg:px-24 pt-12 w-full`}
    >
      <div className={`${styles.header__logo}`}>
        <span className="sr-only">Spotify Advertising</span>
        <SpotifyLogo />
      </div>

      <div className={`${styles.header__menu} relative`}>
        <button
          type="button"
          onClick={() => setNavOpen(!navOpen)}
          className={`${styles.header__menuButton} ${navOpen ? styles.open : ''}`}
          aria-pressed="false"
        >
          <HelpIcon />
          <span className="sr-only">Open Menu</span>
        </button>
        <button
          type="button"
          onClick={openModal}
          className={`${styles.header__menuButtonModal}`}
          aria-pressed="false"
        >
          <HelpIcon />
          <span className="sr-only">Open Menu</span>
        </button>
        <nav className={`${styles.header__menuLinks} ${navOpen ? styles.open : ''} absolute inline-flex`}>
          {navLinks.legal && (
            <a target="_blank" rel="noopener noreferrer" href={navLinks.legal.url}>
              {navLinks.legal.label}
            </a>
          )}
          {navLinks.privacy && (
            <a target="_blank" rel="noopener noreferrer" href={navLinks.privacy.url}>
              {navLinks.privacy.label}
            </a>
          )}
          {navLinks.cookies && (
            <a target="_blank" rel="noopener noreferrer" href={navLinks.cookies.url}>
              {navLinks.cookies.label}
            </a>
          )}
          {navLinks.copyrights && <p>{navLinks.copyrights.label}</p>}
        </nav>
        <Modal
          className={`modal modal-nav ${styles.header__modal}`}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick
          ariaHideApp={false}
        >
          <HelpIcon onClick={closeModal} />
          <nav className={`${styles.header__menuLinks} ${modalIsOpen ? styles.modalOpen : ''}`}>
            {navLinks.legal && (
              <a target="_blank" rel="noopener noreferrer" href={navLinks.legal.url}>
                {navLinks.legal.label}
              </a>
            )}
            {navLinks.privacy && (
              <a target="_blank" rel="noopener noreferrer" href={navLinks.privacy.url}>
                {navLinks.privacy.label}
              </a>
            )}
            {navLinks.cookies && (
              <a target="_blank" rel="noopener noreferrer" href={navLinks.cookies.url}>
                {navLinks.cookies.label}
              </a>
            )}
            {navLinks.copyrights && <p>{navLinks.copyrights.label}</p>}
          </nav>
          <button
            className="button button--transparent"
            type="button"
            onClick={closeModal}
          >
            <span>{navLinks.close.label}</span>
        </button>
        </Modal>
      </div>
    </header>
  );
};

export default Header;

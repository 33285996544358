import React, { useEffect, useState, useRef, useContext } from 'react';
import { StoreContext } from '../../../utils/Store';
import isValidEmail from '../../../utils/emailCheck';

import styles from './formRightLockup.module.scss';

const Form = page => {
  const prop = page.content.content;
  const { swiperInstance, submitOutside, setSubmitOutside, setButtonDisabled, vip, spotifyConnect } = useContext(StoreContext);

  const [welcomeState, setWelcomeState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    errors: {
      firstName: '',
      lastName: '',
      email: '',
    },
    emailIsValid: false,
  });

  const firstName = useRef(null);
  const lastName = useRef(null);
  const email = useRef(null);

  const formValidation = () => {
    const errors = [];
    setSubmitOutside(false);

    return new Promise((resolve, reject) => {
      if (!isValidEmail(welcomeState.email)) {
        errors.push('email');
      }

      if (welcomeState.firstName.length < 2) {
        errors.push('firstName');
      }
      
      if (welcomeState.lastName.length < 2) {
        errors.push('lastName');
      }

      if (errors.length) {
        reject(errors);
      } else {
        localStorage.setItem('first_name', welcomeState.firstName);
        localStorage.setItem('last_name', welcomeState.lastName);
        localStorage.setItem('email', welcomeState.email);

        swiperInstance.slideNext();

        if (vip) {
          spotifyConnect();
        }
      }
    });
  };

  const handleChange = () => {
    const newState = {
      firstName: firstName.current.value.replace(/\s/g, ''),
      lastName: lastName.current.value.replace(/\s/g, ''),
      errors: {
        firstName: '',
        email: '',
      },
    };

    setButtonDisabled(false);

    newState.email = email.current.value;
    if (isValidEmail(newState.email)) {
      newState.emailIsValid = true;
    } else {
      newState.emailIsValid = false;
    }

    setWelcomeState({ ...newState });
  };

  const submitHandler = async () => {
    try {
      await formValidation();
    } catch (errors) {
      const errorState = {};

      errors.forEach(error => {
        errorState[error] = true;
      });

      setWelcomeState({ ...welcomeState, errors: errorState });
    }
  };

  const internalSubmit = event => {
    event.preventDefault();
    submitHandler();
  };

  useEffect(() => {
    if (submitOutside) submitHandler();
  }, [submitOutside]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${styles.formRight} pt-40 md:h-screen md:flex md:items-center md:justify-center md:pb-20 md:pt-48 lg:py-64 xl:pt-48 xl:pb-10 mx-auto`}
    >
      <div className={`${styles.formRight__wrapper} lg:flex lg:items-center lg:justify-between`}>
        <div className={`${styles.formRight__container} lg:w-2/4 lg:mr-8`}>
          <div className="text-block-animation">
            <h1 className={`${styles.formRight__title} form-heading mb-20`}>{prop.heading}</h1>
          </div>
          <form onChange={handleChange} onSubmit={internalSubmit} className="flex flex-wrap justify-between xl:block">
            <div className={`${styles.formRight__lockup} mb-24 relative h-20 inline-block form-input`}>
              <input
                className={`${styles.formRight__input} ${welcomeState.firstName ? 'has-content' : ''} 
                ${welcomeState.errors.firstName ? 'error' : ''}`}
                type="text"
                name="First Name"
                id="firstName"
                required="required"
                ref={firstName}
              />
              <label htmlFor="firstName">
                <p>{prop.input.first_name.label}</p>
              </label>
              {welcomeState.errors.firstName && <p className="color--white text--small pt-2">First name too short</p>}
            </div>
            <div className={`${styles.formRight__lockup} mb-24 relative h-20 inline-block form-input`}>
              <input
                className={`${styles.formRight__input} ${welcomeState.lastName ? 'has-content' : ''} 
                ${welcomeState.errors.lastName ? 'error' : ''}`}
                type="text"
                name="Last Name"
                id="lastName"
                required="required"
                ref={lastName}
              />
              <label htmlFor="lastName">
                <p>{prop.input.last_name.label}</p>
              </label>
              {welcomeState.errors.lastName && <p className="color--white text--small pt-2">Last name too short</p>}
            </div>
            <div className={`${styles.formRight__lockup} mb-24 relative h-20 inline-block form-input`}>
              <input
                className={`${styles.formRight__input} ${welcomeState.email ? 'has-content' : ''} 
                ${welcomeState.errors.email ? 'error' : ''}`}
                type="text"
                name="email"
                id="email"
                required="required"
                ref={email}
              />
              <label htmlFor="email">
                <p>{prop.input.email.label}</p>
              </label>
              {welcomeState.errors.email && <p className="color--white text--small pt-2">Invalid email</p>}
            </div>
            <button type="submit" className="sr-only">
              {prop.next.label}
            </button>
          </form>
        </div>
        <div className={styles.formRight__imageContainer}>
          <img
            src={prop.image}
            alt={prop.group_name}
            className={`${styles.formRight__image} image-right-lockup-image mx-auto lg:mx-0 object-cover`}
          />
        </div>
      </div>
    </div>
  );
};

export default Form;

/* eslint-disable operator-linebreak */
import React, { useState, useEffect, useContext } from 'react';
// import PropTypes from "prop-types"
import Cookies from 'js-cookie';
import gsap, { Sine } from 'gsap';
import { StoreContext } from '../../utils/Store';
import styles from './cookie-bar.module.scss';

const CookieBar = () => {
  const { cms } = useContext(StoreContext);
  const data = cms.content.footer.cookies;
  const [cookiesAccepted, setcookiesAccepted] = useState(!!Cookies.get('cookiesAccepted'));

  const updateCookies = event => {
    event.preventDefault();
    Cookies.set('cookiesAccepted', true, { expires: 365 });
    setcookiesAccepted(true);

    gsap.to('.cookie-bar', 0.4, {
      y: '100%',
      ease: Sine.easeInOut,
    });
  };

  useEffect(() => {
    if (!cookiesAccepted) {
      gsap.to('.cookie-bar', 0.4, {
        y: 0,
        ease: Sine.easeInOut,
      });
    }
  }, [cookiesAccepted]);

  return (
    <div className={`${styles.cookieBar} cookie-bar`}>
      <div className={styles.cookieBar__wrapper}>
        <p className={styles.cookieBar__text}>{data.disclaimer}</p>
        <button
          className={styles.cookieBar__button}
          onClick={e => updateCookies(e)}
          data-event-label="close cookie bar"
          type="button"
        >
          {data.disclaimer_cta}
        </button>
      </div>
    </div>
  );
};

// CookieBar.propTypes = { cms: PropTypes.objectOf(PropTypes.any) }

export default CookieBar;

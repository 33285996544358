import React from 'react';
import Bubbles from '../components/Slides/Bubbles/Bubbles';
import EndScreen from '../components/Slides/EndScreen/EndScreen';
import FormRightLockup from '../components/Slides/FormRightLockup/FormRightLockup';
import ImageRightLockup from '../components/Slides/ImageRightLockup/ImageRightLockup';
import MultiSelect from '../components/Slides/MultiSelect/MultiSelect';
import Quadrant from '../components/Slides/Quadrant/Quadrant';
import QuadTiles from '../components/Slides/QuadTiles/QuadTiles';
import FullWidthLockup from '../components/Slides/FullWidthLockup/FullWidthLockup';

const Components = {
  Bubbles,
  EndScreen,
  FormRightLockup,
  ImageRightLockup,
  MultiSelect,
  Quadrant,
  QuadTiles,
  FullWidthLockup,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default page => {
  // component does exist
  if (typeof Components[page.template] !== 'undefined') {
    const Cpt = Components[page.template];
    return <Cpt key={page.id} content={page} />;
  }
  return false;
};

import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/styles.scss';
// util to simulate jQuery.parent
import closestParent from './app/utils/closestParent';
import sendGaEvent from './app/utils/sendGaEvent';

import Container from './app/Container';
import StoreProvider from './app/utils/Store';

document.addEventListener('DOMContentLoaded', () => {
  // function to send the GA event
  const handleGaEvent = event => {
    const element = closestParent(event.target, '[data-event-label]', true);
    if (element) {
      const eventLabel = element
        .getAttribute('data-event-label')
        .trim()
        .toLowerCase();

      sendGaEvent(eventLabel);
    }
  };
  document.addEventListener('click', handleGaEvent, false);
});

ReactDOM.render(
  <StoreProvider>
    <Container />
  </StoreProvider>,
  document.getElementById('root')
);

import React from 'react';
import styles from './quadrant.module.scss';

import image from '../../../../assets/img/gradient-square.png';

const Quadrant = page => {
  const prop = page.content.content;
  const artists = prop.data;

  return (
    <div
      className={`${styles.quadrant} md:h-screen md:flex md:flex-col md:justify-center items-center pt-40 pb-20 md:pt-32 md:pb-48 lg:py-56 xl:pt-40 xl:pb-48`}
    >
      <div
        className={`${styles.quadrant__wrapper} heading xl:flex xl:justify-between xl:items-end w-full mb-10 xl:mb-24 mx-auto`}
      >
        <div className="mr-12">
          <p className={`${styles.quadrant__subtitle} mb-4 xl:mb-0`}>{prop.group_name}</p>
          <div className="text-block-animation">
            <h1 className={`${styles.quadrant__title} mb-10 xl:mb-0`}>{prop.heading}</h1>
          </div>
        </div>
        <p className={`${styles.quadrant__description} mb-4 md:mb-12 lg:mb-20 xl:mb-0`}>{prop.description}</p>
      </div>
      <div className={`${styles.quadrant__mobileList} md:hidden px-10 pt-10 pb-4`}>
        {artists.map((artist, index) => {
          return (
            <p key={artist.name} className={`${styles.quadrant__mobileItem} pb-6`}>
              {index + 1}.<span className="pl-2">{artist.name}</span>
            </p>
          );
        })}
      </div>
      <div
        className={`${styles.quadrant__artists} hidden md:grid gap-12 grid-rows-5 grid-cols-1 md:grid-rows-2 md:grid-cols-4 justify-center xl:px-48`}
      >
        {artists.map((artist, index) => {
          return (
            <div key={artist.name} className={styles.quadrant__artist}>
              {index === 0 && <img className={styles.quadrant__gradient} src={image} alt="" />}
              <div
                className={`${styles.quadrant__artistLockup} ${
                  artist.image === null ? styles.noImage : ''
                } relative overflow-hidden h-full`}
              >
                <p className={styles.quadrant__artistName}>
                  {index + 1}.<span className="pl-2">{artist.name}</span>
                </p>
                {artist.image !== null && (
                  <img
                    src={artist.image}
                    alt={artist.name}
                    className={`${styles.quadrant__image} quadrant-image h-full w-full`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Quadrant;

import React, { useContext, useState, useEffect } from 'react';
import { StoreContext } from '../../../utils/Store';
import { isMobile } from 'react-device-detect';
import styles from './multiSelect.module.scss';

const MultiSelect = page => {
  const { setButtonDisabled, buttonDisabled } = useContext(StoreContext);
  const [buttonSelected, setButtonSelected] = useState(null);

  const prop = page.content.content;
  const { groups } = prop;

  const updateGroup = (event, index) => {
    localStorage.setItem('group_id', event.target.id);
    setButtonDisabled(false);
    setButtonSelected(index);
    document.getElementById('audienceSelect').options[index].selected = true;
  };

  const updateGroupMobile = event => {
    localStorage.setItem('group_id', event.target.value);
    setButtonDisabled(false);
    setButtonSelected(event.target.selectedIndex);
  };

  useEffect(() => {
    if (isMobile) {
      localStorage.setItem('group_id', groups[0].id);
      setButtonDisabled(false);
    }

    window.addEventListener('resize', () => {
      if (isMobile) {
        if (localStorage.getItem('group_id') === null) {
          localStorage.setItem('group_id', groups[0].id);
        }
        setButtonDisabled(false);
      } else {
        if (localStorage.getItem('group_id') !== null) {
          setButtonDisabled(false);
        } else {
          setButtonDisabled(true);
        }
      }
    });
  }, [buttonDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`${styles.multiSelect} pt-40 md:h-screen md:flex md:items-center md:py-48 lg:py-64 xl:py-48 mx-auto`}
    >
      <div>
        <div className="text-block-animation">
          <h2 className={`${styles.multiSelect__title} mb-12 xl:mb-2`}>{prop.heading}</h2>
        </div>
        {prop.group_name && <p>{prop.group_name}</p>}

        {prop.description && <p className={`${styles.multiSelect__description} mb-16 xl:mb-24`}>{prop.description}</p>}

        <select
          id="audienceSelect"
          className={`${styles.multiSelect__mobile} md:hidden mb-20`}
          onChange={updateGroupMobile}
        >
          {groups.map(group => {
            return (
              <option value={group.id} key={group.id}>
                {group.name}
              </option>
            );
          })}
        </select>

        <div className={`${styles.multiSelect__buttonWrapper} xl:w-4/5 hidden md:block`}>
          {groups.map((group, index) => {
            return (
              <button
                type="button"
                id={group.id}
                key={group.id}
                className={`${styles.multiSelect__button} ${
                  buttonSelected === index ? styles.selected : ''
                } button mb-8 mr-8`}
                onClick={e => updateGroup(e, index)}
              >
                {group.name}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultiSelect;
